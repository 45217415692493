import { Main } from 'wikr-core-analytics';
import { DataProvider } from 'testania';
import { SENTRY_PAYMENT, ERROR_LEVELS, SOLID_ERROR_CODES_GROUPS, SOLID_ERROR_CODES } from 'sentry-utils';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectUser } from 'redux/User/selectors';
import {
    selectValidatePaymentData,
    selectPayment,
    selectSkipUpsell,
    selectSkipUpsellCoaching,
} from 'redux/Payment/selectors';
import {
    initPayPal as initPayPalAction,
    initPaymentGenerator as initPaymentGeneratorAction,
    setLoadingStatus,
    setIsPaymentScreen,
    changePaymentMethod,
} from 'redux/Payment/actions';

import { PAYMENT_TYPES } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';

import useValidatePayment from 'hooks/useValidatePayment';

import { isString } from 'helpers/root/utils';

import { ReplaceSubscriptionResponse } from 'types/payments/replaceSubscription';
import { Currency, IPaymentPageInfo, IUsePaymentLibData, ProductCode } from 'types/payments/payments';

interface IUsePaymentLib {
    pageInfo: IPaymentPageInfo;
    toNextPage: ({ isValid }: { isValid: boolean }) => void;
}

const URL_UPSELL_SUB_COURSE_TECH = 'upsell-sub-course-tech';

export function usePaymentLib({ pageInfo, toNextPage }: IUsePaymentLib): IUsePaymentLibData {
    const dispatch = useDispatch();
    const history = useHistory();
    const skipUpsell = useSelector(selectSkipUpsell);
    const skipUpsellCoach = useSelector(selectSkipUpsellCoaching);

    const validatePaymentData = useSelector(selectValidatePaymentData);
    const { currentProduct, currency } = useSelector(selectPayment);
    const { user_id, country } = useSelector(selectUser);

    const paymentType = pageInfo.products[0].payment_type;

    const validatePayment = useValidatePayment({ pageInfo });

    useEffect(() => {
        dispatch(setIsPaymentScreen(true));

        return () => {
            dispatch(setIsPaymentScreen(false));
        };
    }, []);

    useEffect(() => {
        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            if (skipUpsell) {
                const finishPath = DataProvider.getPathTestaniaPaidFinish();

                history.replace(finishPath);
            } else if (skipUpsellCoach) {
                history.push(URL_UPSELL_SUB_COURSE_TECH);
            } else {
                toNextPage({
                    isValid: validatePaymentData.result,
                });
            }
        }
    }, [validatePaymentData]);

    function getCurrencyName(currency: Currency) {
        return isString(currency) ? JSON.parse((currency as unknown) as string).name : currency.name;
    }

    const getErrorReasonGroup = (errorCode: string) => {
        let reason = 'SOLID_NOT_STANDARD_ERROR';

        SOLID_ERROR_CODES_GROUPS.map(({ name, errorList }) => {
            if (errorList.includes(errorCode)) {
                reason = name;
            }
        });

        return reason;
    };

    function initPaymentGenerator() {
        const metaBank = {
            userId: Number(user_id),
            payment_method: PAYMENT_TYPES.CREDIT_CARD,
            country,
            currency: getCurrencyName(currency),
            product_code: currentProduct?.product_code as ProductCode,
        };

        dispatch(initPaymentGeneratorAction({ ...currentProduct, ...metaBank }));
    }

    function initPayPal() {
        const meta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            price: currentProduct?.fullPrice || 1,
            product_code: currentProduct?.product_code,
            country,
            currency: getCurrencyName(currency),
            successHandler: (data: ReplaceSubscriptionResponse) => {
                const errorCode = data?.error?.code;

                if (errorCode && SOLID_ERROR_CODES.includes(errorCode)) {
                    dispatch(setLoadingStatus(false));

                    initPayPal();

                    sentry.logError(
                        new Error('PayPal error'),
                        SENTRY_PAYMENT,
                        ERROR_LEVELS.CRITICAL,
                        {
                            ...currentProduct,
                            ...meta,
                        },
                        [
                            ['SOLID_ERROR_CODE', errorCode],
                            ['SOLID_ERROR_MESSAGE', data?.error?.recommended_message_for_user || ''],
                            ['SOLID_METHOD', data?.order?.method],
                            ['reason', getErrorReasonGroup(errorCode)],
                        ]
                    );
                } else {
                    dispatch(changePaymentMethod('PAYPAL'));

                    validatePayment('payPal', meta.payment_method, data);
                }
            },
            errorHandler: (e: Error) => {
                sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
                    ...currentProduct,
                    ...meta
                });
                dispatch(setLoadingStatus(false));
            },
            onClickHandler: () => {
                dispatch(changePaymentMethod('PAYPAL'));
                dispatch(setLoadingStatus(false));

                Main.customData('processor_form__click', { payment: 'paypal', event_label: 'paypal' });
            },
            readyHandler: () => {
                dispatch(setLoadingStatus(false));

                Main.customData('processor_form__load', { payment: 'paypal', event_label: 'paypal' });
            },
        };

        // need revert fix on code-freeze period | https://app.asana.com/0/1202352529812330/1203091595393043/f
        // @ts-ignore
        dispatch(initPayPalAction(meta));
    }

    return {
        paymentType,
        payment: {
            initPayPal: () => initPayPal(),
            initPaymentGenerator: () => initPaymentGenerator(),
        },
    };
}
