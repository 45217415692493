import { useCallback } from 'react';

import { ScrollIntoViewOptions, scrollIntoView } from 'helpers/scroll';

/**
 * Returns callback for scrolling element into view
 * @param {string} elementId - element id
 * @param {ScrollIntoViewOptions | boolean} options - options for scrollIntoView
 * @returns {() => void} scrollIntoView callback
 */
const useScrollIntoView = (elementId: string, options?: ScrollIntoViewOptions | boolean) => {
    return useCallback(() => {
        scrollIntoView(elementId, options);
    }, [elementId, options]);
};

export { useScrollIntoView };
