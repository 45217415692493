import React from 'react';

export interface UseMultipleSelectionParams {
    noneOption?: string;
    initialValue?: string[];
}

export const useMultipleSelection = ({ initialValue, noneOption }: UseMultipleSelectionParams = {}) => {
    const [selectedItems, setSelectedItems] = React.useState<string[]>(initialValue ? [...initialValue] : []);

    const checkNoneOption = (value: string) => value === noneOption;

    const onChange = (value: string) => {
        if (noneOption) {
            // reset all other options if "None" is selected or reset "None" if any other option is selected
            if (checkNoneOption(value) || (selectedItems.length === 1 && checkNoneOption(selectedItems[0]))) {
                setSelectedItems([value]);

                return;
            }
        }

        const filteredCurrentValue = selectedItems.filter((v) => v !== value);

        if (filteredCurrentValue.length === selectedItems.length) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems(filteredCurrentValue);
        }
    };

    return {
        selectedItems,
        isDisabled: !selectedItems.length,
        isSelected: (value: string) => selectedItems.includes(value),
        onChange,
    };
};
