import config from 'config';

const yahooInit = () => {
    (function (w, d, t, r, u) {
        w[u] = w[u] || [];
        w[u].push({ projectId: config.YAHOO_PROJECT_ID, properties: { pixelId: config.YAHOO_PIXEL_ID } });
        const s = d.createElement(t);
        s.src = r;
        s.async = true;
        s.onload = s.onreadystatechange = function () {
            let y,
                rs = this.readyState,
                c = w[u];
            // eslint-disable-next-line eqeqeq
            if (rs && rs != 'complete' && rs != 'loaded') {
                return;
            }
            try {
                // eslint-disable-next-line no-undef
                y = YAHOO.ywa.I13N.fireBeacon;
                w[u] = [];
                w[u].push = function (p) {
                    y([p]);
                };
                y(c);
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };
        const scr = d.getElementsByTagName(t)[0],
            par = scr.parentNode;
        par.insertBefore(s, scr);
    })(window, document, 'script', 'https://s.yimg.com/wi/ytc.js', 'dotq');
};

export default yahooInit;
