import React, { useEffect, useState } from 'react';

interface IUseVisibleInViewportOptions {
    offset: {
        top?: number;
        left?: number;
        bottom?: number;
        right?: number;
    };
    threshold?: number | number[];
}

/**
 * Returns true if element is visible in viewport
 * @param {React.RefObject<HTMLElement>} element - element ref
 * @param {IUseVisibleInViewportOptions} options - options for IntersectionObserver
 * @returns {boolean} is visible
 */

const useVisibleInViewport = (
    element: React.RefObject<HTMLElement>,
    options: IUseVisibleInViewportOptions = { offset: {}, threshold: 0 }
): boolean => {
    const { top = 0, left = 0, bottom = 0, right = 0 } = options.offset;
    const { threshold } = options;

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                rootMargin: `${top}px ${right}px ${bottom}px ${left}px`,
                threshold,
            }
        );

        if (element.current) {
            observer.observe(element.current);
        }

        return () => {
            if (element.current) {
                observer.unobserve(element.current);
            }
        };
    }, [element, top, right, bottom, left]);

    return isVisible;
};

export { useVisibleInViewport };
