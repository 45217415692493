import { Main } from 'wikr-core-analytics';
import { useEffect } from 'react';

import Yahoo from 'services/Yahoo';

import { getKgWeight, replaceToUnderscore, generateQueryParams } from 'helpers/utils';

interface trackOptions {
    isPrefix?: boolean;
    data?: object;
    selectedSystems?: unknown;
}

interface ISendAnalyticsWithEventLabel {
    url?: string;
    eventLabel?: any;
}

export const useAnalytics = (trackFlag = false) => {
    const analytic = Main;

    useEffect(() => {
        const url = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');

        if (!trackFlag) {
            analytic.track(url);
        }
    }, []);

    function track(event: string, options?: trackOptions) {
        analytic.track(event, options);
    }

    function purchase(data: any) {
        analytic.purchase(data);

        Yahoo.push({ et: 'custom', ea: 'purchase', gv: String(data.value) });
    }

    function customData(event_name: string, data?: any) {
        analytic.customData(event_name, data);
    }

    const sendAnalyticsWithEventLabel = ({
        url = window.location.pathname,
        eventLabel,
    }: ISendAnalyticsWithEventLabel) => {
        const urlParams = generateQueryParams();
        const formattedUrl = url.replace(/(\/([a-z]{2})\/)|\//g, '');

        // for case when need send unit values
        if (typeof eventLabel === 'object' && eventLabel.value) {
            eventLabel = getKgWeight(eventLabel);
        }

        const eventName = `${replaceToUnderscore(formattedUrl)}__click`;

        customData(eventName, { event_label: eventLabel, urlParams });
    };

    return {
        track,
        purchase,
        customData,
        sendAnalyticsWithEventLabel,
    };
};
