import React from 'react';

import { OnboardingPages, PaymentPages, StartPages, ResultPages, UpsellPages } from 'router/routesMap/publicRoutesMap';

import { CABINET_DOMAIN } from 'constants/links';
import { CABINET_URL_LIST } from '../constants';

import { deleteSlash } from 'helpers/utils';
import { lazyLoad, lazyWithRetry } from 'helpers/lazyWithRetry';

type RouteItem = {
    path: string;
    route: string;
};

export const pathWithRegularExpression = (path: string) => `/:language([a-z]{2})?/${path}`;

export const generateRoute = ({ route, pathToFile, isDevMode }: {
    route: string;
    pathToFile: string;
    isDevMode: boolean;
}) => {
    const PageComponent =
        isDevMode ?
            lazyWithRetry(() => import(`../../pages/${pathToFile}`))
            :
            lazyLoad({ importer: () => import(`../../pages/${pathToFile}`) });

    return {
        path: pathWithRegularExpression(route),
        key: route,
        render: (props: any) => <PageComponent key={pathToFile} {...props} />,
    };
};

export const checkRedirectToCabinet = (urlPath: string) => {
    const urlPathValue = deleteSlash(urlPath);

    CABINET_URL_LIST.forEach(({ oldUrl, newUrl }) => {
        const regex = new RegExp(`^${oldUrl}$`);
        const isOldCabinetUrl = regex.test(urlPathValue);

        if (isOldCabinetUrl) {
            window.location.href = `https://${CABINET_DOMAIN}/${newUrl}`;

            return;
        }
    });
};

const formatRouteList = (routeList: RouteItem[]) => {
    const formattedRouteList: { route: string }[] = [];

    routeList.map((item: RouteItem) => formattedRouteList.push({ route: item.route }));

    return formattedRouteList;
};

export const setRouteList = () => {
    window.ROUTES_LIST = {
        onboardingPages: formatRouteList(OnboardingPages),
        paymentPages: formatRouteList(PaymentPages),
        startPages: formatRouteList(StartPages),
        upsellPages: formatRouteList(UpsellPages),
        resultPages: formatRouteList(ResultPages),
    };
};
