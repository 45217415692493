import { Main } from 'wikr-core-analytics';
import Solid from 'solid-payment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { selectUserId } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';
import { selectPaymentMethod, selectCurrentProduct, selectPurchaseWithoutValue } from 'redux/Payment/selectors';
import { SET_REPLACE_SUBSCRIPTION, VALIDATE_PAYMENT } from 'redux/Payment/actionTypes';
import { replaceSubscription as replaceSubscriptionAction } from 'redux/Payment/actions';

import { PAYMENT_TYPES_NAME, PAYMENT_TYPES } from 'constants/payments';

import { cleanObject, fromPennyToInt, getPriceFromCents, generateQueryParams } from 'helpers/utils';
import getContentId from './helpers/getContentId';

import { ValidateSubscriptionPayload } from 'types/payments/validate';
import { ISendAnalyticPurchase, ReplaceSubscriptionResponse } from 'types/payments/replaceSubscription';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';

const getUserId = (state: RootState) => selectUserId(state);
const getTestaniaName = (state: RootState) => selectTestaniaName(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const getPurchaseWithoutValue = (state: RootState) => selectPurchaseWithoutValue(state);

function* replaceSubscriptionMethod({ payload }: ReturnType<typeof replaceSubscriptionAction>) {
    const { system, subscriptionData, screenId, toNextPage, analytics } = payload;

    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const purchaseWithoutValue: boolean = yield select(getPurchaseWithoutValue);

    const paymentMethodName: number | null = PAYMENT_TYPES[paymentMethod] || null;

    const payloadValidateData: ValidateSubscriptionPayload = {
        paymentMethod: paymentMethodName,
        screenId,
        system,
        withoutSendPurchaseEvent: true,
        analytics,
        upgradedProduct: subscriptionData,
    };

    try {
        const response: ReplaceSubscriptionResponse = yield Solid.replaceSubscription(system, subscriptionData);

        if (response) {
            payloadValidateData.data = response;

            if (!purchaseWithoutValue) {
                yield call(sendAnalyticPurchase, { ...response, screenId: screenId });
            }

            if (purchaseWithoutValue) yield put({ type: VALIDATE_PAYMENT, payload: payloadValidateData });

            toNextPage();
        }
    } catch ({ error }) {
        console.error('error', error);
    }
}

function* sendAnalyticPurchase(payload: ISendAnalyticPurchase) {
    const {
        order: { amount, currency, subscription_id },
        screenId,
    } = payload;

    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const userId: number = yield select(getUserId);
    const testaniaName: string = yield select(getTestaniaName);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;
    const paymentType = currentProduct?.payment_type;

    const content_id = getContentId({
        paymentType,
        currentProductCode: currentProduct?.product_code,
        trialPeriod: currentProduct?.trial,
        regularPeriod: currentProduct?.period,
        trialPrice: amount,
        regularPrice: fromPennyToInt(currentProduct.start_price),
    });
    const urlParams: string = generateQueryParams();

    const options = {
        currency: currency,
        value: Number(getPriceFromCents(currentProduct?.ltv)),
        content_id,
        price: fromPennyToInt(amount),
        payment: paymentMethodName,
        user_id: userId,
        order_id: payload.order.order_id,
        subscription_id: subscription_id,
        screen_id: screenId,
        tariff: currentProduct?.id,
        ab_test_name: testaniaName,
        urlParams,
        order_type: payload?.paymentType,
    };

    Main.purchase(cleanObject(options), { selectedSystems: ['amazon', 'ga'] });
}

export const replaceSubscription = [takeLatest(SET_REPLACE_SUBSCRIPTION, replaceSubscriptionMethod)];
