import { ERROR_LEVELS } from 'sentry-utils';
import { useEffect, useState } from 'react';

import {
    OnboardingPages,
    StartPages,
    PaymentPages,
    OtherPages,
    UpsellPages,
    ResultPages,
} from 'router/routesMap/publicRoutesMap';

import { CHUNK_LOAD_FAILED } from 'helpers/lazyWithRetry/constants';

import sentry from 'services/Sentry/SentryInstance';

import { replaceUnderscore } from 'helpers/utils';

export interface UsePreloadNextPage {
    getNextPage?: () => {
        config: object;
        id: string;
        name: string;
    };
}

const routesMap = [...OnboardingPages, ...StartPages, ...PaymentPages, ...OtherPages, ...UpsellPages, ...ResultPages];

export const usePreloadNextPage = ({ getNextPage }: UsePreloadNextPage) => {
    const [nextPageId, setNextPageId] = useState<string | null>(null);

    useEffect(() => {
        getNextPageInfo(setNextPageId);
    }, []);

    useEffect(() => {
        let timerId: ReturnType<typeof setTimeout>;

        if (nextPageId) {
            const actualRoute = routesMap.find((item) => item.route === nextPageId);

            timerId = setTimeout(() => {
                import(`../pages/${actualRoute?.path}`).catch((error) => {
                    const errorMessage = error?.message ? `PRELOAD FAILED: ${error?.message}` : error.toString();

                    console.error(errorMessage);

                    sentry.logError(new Error(errorMessage), CHUNK_LOAD_FAILED, ERROR_LEVELS.ERROR);
                });
            }, 0);
        }

        return () => clearInterval(timerId);
    }, [nextPageId]);

    async function getNextPageInfo(cb: (id: string) => void) {
        const nextPageInfo = await getNextPage?.();

        nextPageInfo && cb(replaceUnderscore(nextPageInfo?.id));
    }
};
