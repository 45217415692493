import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { validatePayment } from 'redux/Payment/actions';

import { getArrayFromString } from 'helpers/utils';

import { IPaymentPageInfo } from 'types/payments/payments';

const useValidatePayment = ({ pageInfo }: { pageInfo: IPaymentPageInfo }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (system: string, paymentMethod: number, data: any) => {
        const analyticsList = pageInfo?.config?.analytics;

        const payload = {
            system,
            paymentMethod,
            data,
            screenId: history.location.pathname,
            ...(analyticsList && { analytics: getArrayFromString(analyticsList) }),
        };

        dispatch(validatePayment(payload));
    };
};

export default useValidatePayment;
