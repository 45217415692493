import { CurrentProduct } from 'types/payments/payments';

import { APP_FULL_ACCESS } from './payments';

export const EXCLUSIVE_OFFER_PRODUCT_MXN: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1050,
    name: 'One-week exclusive offer + Three-months LATAM',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 1699,
    product_state: 'per_day',
    start_price: 51599,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_BRL: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1050,
    name: 'One-week exclusive offer + Three-months LATAM',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 495,
    product_state: 'per_day',
    start_price: 14800,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_COP: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1050,
    name: 'One-week exclusive offer + Three-months LATAM2',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 399900,
    product_state: 'per_day',
    start_price: 11900000,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_PEN: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1050,
    name: 'One-week exclusive offer + Three-months LATAM2',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 399,
    product_state: 'per_day',
    start_price: 10900,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_ZAR: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1050,
    name: 'One-week exclusive offer + Three-months LATAM2',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 1999,
    product_state: 'per_day',
    start_price: 55900,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_TWD: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1000,
    name: 'One-week exclusive offer + Three-months LATAM2',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 2999,
    product_state: 'per_day',
    start_price: 94900,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_PHP: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1000,
    name: 'One-week exclusive offer + Three-months LATAM2',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 4999,
    product_state: 'per_day',
    start_price: 165900,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_GBP: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1500,
    name: 'One-week exclusive offer + Three-months LATAM',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 99,
    product_state: 'per_day',
    start_price: 2999,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_EUR: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1500,
    name: 'One-week exclusive offer + Three-months LATAM',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 99,
    product_state: 'per_day',
    start_price: 2999,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};

export const EXCLUSIVE_OFFER_PRODUCT_US: CurrentProduct = {
    id: '98ab09d8-4403-4124-a13d-0caff219a76f',
    ltv: 1500,
    name: 'One-week exclusive offer + Three-months LATAM',
    is_preselected: false,
    payment_type: 'subscription',
    period: 90,
    price: 99,
    product_state: 'per_day',
    start_price: 2999,
    price_per_state: 1,
    start_price_per_state: 1,
    trial: 7,
    product_code: APP_FULL_ACCESS,
};
