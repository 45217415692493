import { useSelector } from 'react-redux';

import { selectCountry } from 'redux/User/selectors';

import {
    EXCLUSIVE_OFFER_PRODUCT_BRL,
    EXCLUSIVE_OFFER_PRODUCT_EUR,
    EXCLUSIVE_OFFER_PRODUCT_GBP,
    EXCLUSIVE_OFFER_PRODUCT_MXN,
    EXCLUSIVE_OFFER_PRODUCT_US,
    EXCLUSIVE_OFFER_PRODUCT_COP,
    EXCLUSIVE_OFFER_PRODUCT_PEN,
    EXCLUSIVE_OFFER_PRODUCT_ZAR,
    EXCLUSIVE_OFFER_PRODUCT_TWD,
    EXCLUSIVE_OFFER_PRODUCT_PHP,
} from 'constants/exclusiveOfferProduct';
import {
    COUNTRIES_EURO_LIST,
    COUNTRIES_GBP_LIST,
    COUNTRIES_BRL_LIST,
    COUNTRIES_MX_LIST,
    COUNTRIES_COP_LIST,
    COUNTRIES_PEN_LIST,
    COUNTRIES_ZAR_LIST,
    COUNTRIES_TWD_LIST,
    COUNTRIES_PHP_LIST,
} from 'constants/countriesData';

export const useExclusiveOfferProduct = () => {
    const userCountry = useSelector(selectCountry);

    const checkIsCurrentCountryArea = (list: string[]) => list.includes(userCountry as string);

    switch (true) {
        case checkIsCurrentCountryArea(COUNTRIES_EURO_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_EUR;

        case checkIsCurrentCountryArea(COUNTRIES_GBP_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_GBP;

        case checkIsCurrentCountryArea(COUNTRIES_BRL_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_BRL;

        case checkIsCurrentCountryArea(COUNTRIES_MX_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_MXN;

        case checkIsCurrentCountryArea(COUNTRIES_COP_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_COP;

        case checkIsCurrentCountryArea(COUNTRIES_PEN_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_PEN;

        case checkIsCurrentCountryArea(COUNTRIES_ZAR_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_ZAR;

        case checkIsCurrentCountryArea(COUNTRIES_TWD_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_TWD;

        case checkIsCurrentCountryArea(COUNTRIES_PHP_LIST):
            return EXCLUSIVE_OFFER_PRODUCT_PHP;

        default:
            return EXCLUSIVE_OFFER_PRODUCT_US;
    }
};
