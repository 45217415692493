import { UnitInput as LibSingleUnitInput } from 'wikr-core-components';
import React, { ChangeEvent, KeyboardEvent, RefObject, useEffect, useRef } from 'react';

import { enableOnlyDigitsInput, setOnlyPositiveNumber, translate } from 'helpers/utils';

import { IUnitInputProps } from './types';

const UnitInput = ({ dataLocator, setValue, value, unit, hint, placeholder, showHint, maxLength }: IUnitInputProps) => {
    const ref = useRef() as RefObject<HTMLInputElement>;

    useEffect(() => {
        const maxLengthAmount = maxLength || 3;

        if (value?.length <= maxLengthAmount) {
            const triggeredElement = ref.current;

            if (triggeredElement) triggeredElement.style.width = setOnlyPositiveNumber(value?.length) + 0.1 + 'ch';
        }
    }, [value]);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const triggeredElement = e.target;
        const { value = '' } = e.target;

        const maxLengthAmount = maxLength || 3;

        if (value?.length <= maxLengthAmount) {
            triggeredElement.style.width = setOnlyPositiveNumber(value?.length) + 0.1 + 'ch';
        }

        setValue(value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        enableOnlyDigitsInput(e);
    };

    return (
        <LibSingleUnitInput
            unit={translate(unit ?? '')}
            placeholder={placeholder}
            value={value}
            hint={hint}
            showHint={showHint}
            dataLocator={dataLocator}
            filled={!!value}
            onChange={onChangeHandler}
            onKeyDown={handleKeyDown}
            inputRef={ref}
        />
    );
};

export { UnitInput };
