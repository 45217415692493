import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { Trans } from 'react-i18next';
import React from 'react';

import { selectUIEffects } from 'redux/UiEffects/selectors';
import { hideModal } from 'redux/UiEffects/actions';

import { getProjectEmail } from 'helpers/settings';

import Title from 'components/Title';
import Text from 'components/Text';
import ModalClose from 'components/ModalClose';
import Button from 'components/Button';

import styles from './NotificationModal.module.css';

import IconError from './img/issueProblem.svg';
import IconInfo from './img/issueInfo.svg';

ReactModal.setAppElement('#app');

function NotificationModal() {
    const dispatch = useDispatch();

    const uiEffectsProps = useSelector(selectUIEffects);
        const { issueType, issueDescription, issueTitle, modalIsOpen } = uiEffectsProps;

    const handleClose = () => dispatch(hideModal());

    return (
        <ReactModal
            shouldCloseOnEsc={false}
            isOpen={modalIsOpen}
            onRequestClose={handleClose}
            contentLabel="Modal"
            className={styles.notificationModal}
            overlayClassName={styles.Overlay}
            bodyOpenClassName={styles.ReactModalBodyOpen}
        >
            <ModalClose onClick={handleClose} notificationModalIcon />
            {issueType === 'ERROR' ?
                (
                    <IconError className={styles.notificationModalIcon} />
                ) : (
                    <IconInfo className={styles.notificationModalIcon} />
                )
            }
            <Title h3>
                <Trans>{issueTitle}</Trans>
            </Title>
            <Text small grey globalClassNames={['mb-16']}>
                <Trans>{issueDescription}</Trans>
            </Text>
            <Text small globalClassNames={['colorBrand', 'mb-24']}>
                <a href={'mailto:' + getProjectEmail()}>{getProjectEmail()}</a>
            </Text>
            <Button onClick={handleClose} primary notificationBtn globalClassNames={['mb-32']} title="GOT IT" />
        </ReactModal>
    );
}

export default NotificationModal;
