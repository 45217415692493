export interface ScrollIntoViewOptions {
    block?: 'start' | 'center' | 'end';
    behavior?: 'auto' | 'smooth';
    offset?: number;
}

const scrollIntoView = (elementId: string, options?: ScrollIntoViewOptions | boolean) => {
    const element = document.getElementById(elementId);

    if (!element) {
        return;
    }

    const { top, height, bottom } = element.getBoundingClientRect();
    const pageYOffset = window.pageYOffset;
    const windowInnerHeight = window.innerHeight;
    const offset = typeof options !== 'boolean' ? options?.offset || 0 : 0;

    const verticalScrollCoordinateMap = {
        start: pageYOffset + top + offset,
        center: pageYOffset + top - (windowInnerHeight - height) / 2 + offset,
        end: pageYOffset + bottom - windowInnerHeight + offset,
    };

    if (typeof options === 'boolean') {
        window.scrollTo({
            top: options ? verticalScrollCoordinateMap['start'] : verticalScrollCoordinateMap['end'],
            behavior: 'auto',
        });

        return;
    }

    window.scrollTo({
        top: verticalScrollCoordinateMap[options?.block || 'start'],
        behavior: options?.behavior || 'auto',
    });
};

export { scrollIntoView };
