import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectDynamicConfig, selectGender } from 'redux/Onboadring/selectors';

import {
    ResponsePlanDynamicConfigsDataItem,
    UserDynamicConfig,
} from 'types/onboarding/dynamicConfig';

export function useDataFromDynamicConfig() {
    const gender = useSelector(selectGender);
    const dynamicConfig = useSelector(selectDynamicConfig);

    const [userDynamicConfig, setUserDynamicConfig] = useState<UserDynamicConfig | null>(null);

    useEffect(() => {
        if (dynamicConfig) {
            dynamicConfig.map((data: ResponsePlanDynamicConfigsDataItem) => {
                if (data.gender === gender) {
                    setUserDynamicConfig({
                        body_parts: data.body_parts,
                        equipment: data.equipment,
                        goals: data.goals,
                        plan_settings: data.plan_settings,
                    });
                }
            });
        }

    }, [dynamicConfig]);

    return userDynamicConfig;
}
