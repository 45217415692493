import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

import { selectMeasureSystem } from 'redux/Onboadring/selectors';
import { setMeasure } from 'redux/Onboadring/actions';

import { MEASURE_SYSTEM } from 'constants/measureUnits';

import { MeasuringSystemType } from 'types/onboarding/onboarding';

interface IUseMeasureUnit {
    measureSystem: MeasuringSystemType;
    setMeasureSystem: (system: string) => void;
    getMeasureSystem: (system: string) => string;
}

export function useMeasureUnit(): IUseMeasureUnit {
    const dispatch = useDispatch();
    const measureSystem: MeasuringSystemType = useSelector(selectMeasureSystem);

    const [measureSystemLocal, setMeasureSystemLocal] = useState(measureSystem);

    const setMeasureSystem = (system: MeasuringSystemType) => {
        setMeasureSystemLocal(system);

        dispatch(setMeasure(system));
    };

    const getMeasureSystem = (unit: string) => {
        return MEASURE_SYSTEM[unit];
    };

    return {
        measureSystem: measureSystemLocal,
        setMeasureSystem,
        getMeasureSystem,
    };
}
