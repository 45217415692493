import { MeasuringSystemType, MeasuringWeightUnits } from 'types/onboarding/onboarding';

interface IMeasureUnitSystem {
    [name: string]: string;
}

type IWeightUnit = {
    [name in MeasuringSystemType]: MeasuringWeightUnits;
};

enum TallUnit {
    IMPERIAL = 'in',
    METRIC = 'cm',
}

export enum WeightUnit {
    IMPERIAL = 'lbs',
    METRIC = 'kg',
}

export const MEASURE_SYSTEM: IMeasureUnitSystem = {
    lbs: 'IMPERIAL',
    kg: 'METRIC',
    in: 'IMPERIAL',
    cm: 'METRIC',
};

export const WEIGHT_UNIT: IWeightUnit = {
    IMPERIAL: WeightUnit.IMPERIAL,
    METRIC: WeightUnit.METRIC,
};

export const TALL_UNIT = {
    IMPERIAL: TallUnit.IMPERIAL,
    METRIC: TallUnit.METRIC,
};

export const DATE_FORMATS: IMeasureUnitSystem = {
    DMY: 'DD/MM/YYYY',
    YMD: 'YYYY-MM-DD',
    MMM: 'MMM',
    DD: 'DD',
    YYYY: 'YYYY',
};
