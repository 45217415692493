import { IOption, IButtonData, OptionBox as LibOptionBox } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { IBaseOption, IBaseButton, IOptionBoxCustom } from './types';

// constant
const BASE_OPTION: IBaseOption = {
    dataLocator: 'option',
    mb: 12,
    borderWidth: 1,
    borderColor: 'border-default',
    backgroundColor: 'surface-main',
    checkedBorderColor: 'surface-main',
    checkedBackgroundColor: 'primary-default',
};
const BASE_BUTTON: IBaseButton = {
    text: 'Continue',
    fixed: true,
    dataLocator: 'obNextStep',
};

const OptionBox: FC<IOptionBoxCustom> = ({ optionData, multiple, onClick, buttonData, fixed, className, mb }) => {
    const { t } = useTranslation();

    const modifiedOptionData: IOption[] = optionData.map((option) => ({
        ...BASE_OPTION,
        ...option,
        title: option.title && t(option.title),
        subtitle: option.subtitle && t(option.subtitle),
        value: option.value || option.title || '',
    }));

    const modifiedButtonData: IButtonData = {
        ...BASE_BUTTON,
        ...buttonData,
        text: t(buttonData?.text || BASE_BUTTON.text),
    };

    return (
        <LibOptionBox
            optionData={modifiedOptionData}
            multiple={multiple}
            onClick={onClick}
            buttonData={modifiedButtonData}
            fixed={fixed}
            className={className}
            mb={mb}
        />
    );
};

export { OptionBox };
