import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { selectUser } from 'redux/User/selectors';
import { setIsAutologinComplete, setIsInitTestania } from 'redux/User/actions';
import { selectRedirectPath } from 'redux/UiEffects/selectors';
import { selectFlowLink } from 'redux/Testania/selectors';
import { setFlowLink } from 'redux/Testania/actions';
import { setGender, setUrlParams as setUrlParamsToStore } from 'redux/Onboadring/actions';

import { PRELOAD_TESTANIA } from 'constants/defaultRoutes';

import { getUrlParams as getUrlParamsFromUrl } from 'helpers/utils';
import { checkRedirectToCabinet } from './helpers/helpers';

import OnboardingDependencies from './components/OnboardingDependencies';

import PublicRouter from './routers/public';

export function Routers() {
    const dispatch = useDispatch();

    const history = useHistory();

    const redirectPath = useSelector(selectRedirectPath);
    const flowLink = useSelector(selectFlowLink);
    const { isInitTestania } = useSelector(selectUser);

    useEffect(() => {
        const urlParamsFromURL = getUrlParamsFromUrl();

        if (!flowLink) {
            dispatch(setFlowLink(location.href));
        }

        if (urlParamsFromURL) {
            // autologin flow, cancel subscription from mobile flow
            const { token, c, sg } = urlParamsFromURL;

            if (!(token || c || sg)) {
                runTestania();
            }

            dispatch(setUrlParamsToStore(urlParamsFromURL));
        } else {
            runTestania();
        }
    }, []);

    useEffect(() => {
        if (redirectPath) {
            history.push(redirectPath);
        }
    }, [redirectPath]);

    const urlPath = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const paramsGender = params.get('gender');

    if (paramsGender) {
        dispatch(setGender(paramsGender));
    }

    const runTestania = () => {
        dispatch(setIsInitTestania(true));
        dispatch(setIsAutologinComplete(true));
    };

    checkRedirectToCabinet(urlPath);

    return (
        <>
            {isInitTestania && <OnboardingDependencies />}
            <Switch>
                {PublicRouter.map(({ ...route }, index) => (
                    <Route key={index} {...route} />
                ))}
                <Redirect to={PRELOAD_TESTANIA} />
            </Switch>
        </>
    );
}
