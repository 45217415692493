const getContentId = ({
    paymentType,
    currentProductCode,
    trialPeriod,
    regularPeriod,
    regularPrice,
    trialPrice,
}: {
    paymentType?: string;
    currentProductCode: string;
    trialPeriod: number;
    regularPeriod: number;
    regularPrice?: number;
    trialPrice: number;
}) => {
    const periodValue = `period: ${trialPeriod}/${regularPeriod}`;
    const priceValue = `price: ${trialPrice}/${regularPrice}`;

    return `${paymentType}: ${currentProductCode} | ${periodValue} | ${priceValue}`;
};

export default getContentId;
