import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

import config from 'config';

import { skipOnboarding } from 'redux/Onboadring/actions';

import useOneTrustLoaded from './useOneTrustLoaded';

export const useSkipOnboarding = () => {
    const dispatch = useDispatch();

    const { oneTrust } = useOneTrustLoaded();

    if (config.ENV === 'stage') {
        useHotkeys('shift+o+b', () => handleSkipOnboarding());
        useHotkeys('shift+r+s', () => handleClearAllStore());
    }

    const handleSkipOnboarding = () => {
        oneTrust?.allowAll();
        dispatch(
            skipOnboarding({
                reload: () => window.location.reload(),
            })
        );
    };

    const handleClearAllStore = () => {
        const isClear = window.confirm('Store has been cleared');

        if (isClear) {
            // clear local storage
            dispatch({ type: 'RESET_STATE' });
            localStorage.clear();

            setTimeout(() => {
                window.location.reload();
            }, 700);

            return;
        }
    };
};
