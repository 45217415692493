import { MultiUnitInput as LibMultiUnitInput } from 'wikr-core-components';
import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';

import { enableOnlyDigitsInput, setOnlyPositiveNumber, translate } from 'helpers/utils';

import { IMultiInputProps } from './types';

const MultiInput = (props: IMultiInputProps) => {
    const { dataLocator, hint, multiInputs, onChangeValue, firstInputValue, secondInputValue, showHint, mb } = props;

    const inchRef = useRef<HTMLInputElement | null>(null);
    const feetRef = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState({
        ft: '',
        in: '',
    });

    const multiInputsKey = Object.keys(multiInputs);

    const shouldMoveThroughInputs = (el: HTMLInputElement) => {
        if (el.value.length >= 1) {
            inchRef.current && inchRef.current.focus();
        } else if (el.value.length === 0) {
            feetRef.current && feetRef.current.focus();
        }
    };

    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const triggeretElement = e.target;
        const inputingValue = triggeretElement.value;

        if (triggeretElement.value.length >= 5) {
            return;
        } else {
            setValue({
                ...value,
                [triggeretElement.name]: inputingValue,
            });
        }

        triggeretElement.style.width = `calc(${setOnlyPositiveNumber(triggeretElement.value.length)}ch + 5px)`; // changing input size
        multiInputs[triggeretElement.name as 'ft' | 'in'] = triggeretElement.value;
        shouldMoveThroughInputs(triggeretElement);
        onChangeValue(multiInputs);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        enableOnlyDigitsInput(e);
    };

    const translatedData = multiInputsKey.map((item) => translate(item));

    return (
        <LibMultiUnitInput
            multiInputs={multiInputsKey}
            translatedData={translatedData}
            feetRef={feetRef}
            inchRef={inchRef}
            placeholder="0"
            hint={hint}
            showHint={showHint}
            dataLocator={dataLocator}
            filledFeet={!!firstInputValue}
            filledInch={!!secondInputValue}
            onChange={inputChangeHandler}
            onKeyDown={handleKeyDown}
            mb={mb}
        />
    );
};

export { MultiInput };
