import { initApiClient } from 'welltech-web-api-client';
import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import appConfig from 'config';

import sentry from 'services/Sentry/SentryInstance';

import { getLanguage } from 'helpers/utils';

function MobileApiClient() {
    const language = getLanguage();

    return initApiClient({
        apiUrl: appConfig.API_URL_MOBILE,
        apiVersion: appConfig.API_VERSION_MOBILE,
        apiKey: appConfig.API_KEY_MOBILE,
        authTokenKey: appConfig.MOBILE_TOKEN_KEY,
        retries: 3,
        timeout: 40000,
        extendResponse: false,
        onUpdateToken: (token: string) => {
            localStorage.setItem(appConfig.MOBILE_TOKEN_KEY, token);
        },
        onRetry: (retryCount, error, requestConfig) => {
            sentry.logError(new Error(`RETRY: ${error.message}`), SENTRY_AXIOS, ERROR_LEVELS.WARNING, {
                ...requestConfig
            });
        },
        onErrorHandler: () => {},
        requestInterceptors: [
            (config) => {
                config.headers['language'] = language;

                return config;
            },
        ],
    });
}

export default MobileApiClient();

