import { AnalyticUtils, Main } from 'wikr-core-analytics';
import { SENTRY_PAYMENT, ERROR_LEVELS } from 'sentry-utils';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import config from 'config';

import api from 'api';

import { selectUserId, selectDefaultAmazonAnalyticData } from 'redux/User/selectors';
import { SET_IS_PAID } from 'redux/User/actionTypes';
import { selectFlowLink, selectTestaniaName } from 'redux/Testania/selectors';
import { RootState } from 'redux/rootReducer';
import {
    selectPaymentMethod,
    selectCurrency,
    selectCurrentProduct,
    selectPurchaseWithoutValue,
    selectMainProduct,
} from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { validatePayment as validatePaymentAction } from 'redux/Payment/actions';

import { PAYMENT_TYPES_NAME } from 'constants/payments';
import { DEFAULT_ACTIVE_ANALYTICS } from 'constants/analytics';

import Yahoo from 'services/Yahoo';
import sentry from 'services/Sentry/SentryInstance';

import { cleanObject, deleteSlash, fromPennyToInt, generateQueryParams, replaceDataWithLocalhost } from 'helpers/utils';
import { getDevicePlatform } from 'helpers/settings';
import { calculateLTV, isSubscription } from 'helpers/payment/utils';
import getContentId from './helpers/getContentId';

import {
    DefaultAmazonPurchaseDataType,
    PostInitResponse,
    ValidatePayload,
    ValidateResponse,
} from 'types/payments/validate';
import { Currency, CurrentProduct, MainProduct, PaymentMethod } from 'types/payments/payments';
import { Awaited } from 'types/commonInterfaces';

const getUserId = (state: RootState) => selectUserId(state);
const getCurrency = (state: RootState) => selectCurrency(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getMainProduct = (state: RootState) => selectMainProduct(state);
const getTestaniaName = (state: RootState) => selectTestaniaName(state);
const getPurchaseWithoutValue = (state: RootState) => selectPurchaseWithoutValue(state);
const getDefaultAmazonPurchaseData = (state: RootState) => selectDefaultAmazonAnalyticData(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });
    yield put({ type: actionTypes.SET_PAYMENT_DATA, payload });

    const {
        data: { order, brand },
        screenId,
        withoutSendPurchaseEvent,
        analytics,
        upgradedProduct,
    } = payload;

    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const mainProduct: MainProduct = yield select(getMainProduct);
    const currency: Currency = yield select(getCurrency);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const purchaseWithoutValue: boolean = yield select(getPurchaseWithoutValue);
    const defaultAmazonPurchaseData: DefaultAmazonPurchaseDataType = yield select(getDefaultAmazonPurchaseData);
    const testaniaName: string = yield select(getTestaniaName);
    const flowLink: string = yield select(getFlowLink);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const isSendPurchase = !withoutSendPurchaseEvent;

    yield put({ type: actionTypes.SET_PAYMENT_TYPE, payload: currentProduct?.payment_type });

    const platform = getDevicePlatform();

    const isTrial = !!currentProduct?.trial;

    const amountValue: number = fromPennyToInt(order?.amount);
    const subscriptionPrice = fromPennyToInt(currentProduct?.start_price);

    const paymentType = currentProduct?.payment_type;
    const isSubscriptionProduct = isSubscription(paymentType);

    const periodName: number = currentProduct.period;

    const trialPeriod: number | null = currentProduct?.trial ?? null;
    const currentProductCode = currentProduct?.product_code;

    // for case with upgradedPlan
    const productId = mainProduct ? upgradedProduct?.product_id : currentProduct?.id;

    const otherAnalyticData: Awaited<ReturnType<typeof AnalyticUtils.getAnalyticData>> = yield call(
        AnalyticUtils.getAnalyticData,
        'purchase'
    );

    const content_id = getContentId({
        paymentType,
        currentProductCode,
        trialPeriod,
        regularPeriod: periodName,
        trialPrice: amountValue,
        regularPrice: subscriptionPrice,
    });

    const analyticData = {
        currency: currency?.name,
        value: calculateLTV(currentProduct?.ltv, currentProduct?.period),
        content_id,
        subscription_price: fromPennyToInt(currentProduct?.start_price),
        price: amountValue,
        payment: paymentMethodName,
        card_type: brand,
        order_id: order?.order_id,
        subscription_id: order?.subscription_id,
        screen_id: deleteSlash(screenId),
        tariff: productId,
        analytics,
        ...defaultAmazonPurchaseData,
    };

    const meta = {
        payment_method: payload.paymentMethod,
        payment_type: currentProduct.payment_type,
        product_id: productId,
        product_code: currentProduct.product_code,
        order_id: order?.order_id,
        subscription_id: order.subscription_id,
        platform,
        trial: isTrial,
        flow_link: flowLink,
        ab_test_name: testaniaName,
        payment_screen: deleteSlash(screenId),
        analytic_data: {
            currency: currency?.name,
            value: calculateLTV(currentProduct?.ltv, currentProduct?.period),
            content_id,
            price: amountValue,
            payment: paymentMethodName,
            order_id: order?.order_id,
            tariff: currentProduct?.id,
            order_type: currentProduct?.payment_type,
            mode: config.ENV,
            release_date: config.RELEASE_DATE,
            card_type: brand,
            subscription_id: order?.subscription_id,
            aws_id: defaultAmazonPurchaseData.aws_id,
            subscription_price: fromPennyToInt(currentProduct?.start_price),
            screen_id: deleteSlash(screenId),
            ...otherAnalyticData,
        },
        ...(isSubscriptionProduct && { subscription_trial_period: trialPeriod }),
        ...(isSubscriptionProduct && currentProduct?.period && { subscription_period: periodName }),
        ...(purchaseWithoutValue && { send_analytics: true }),
        ...(purchaseWithoutValue && !isSendPurchase && { analytic_data: { ...analyticData, ...otherAnalyticData } }),
    };

    const isLocalEnv = import.meta.env.DEV;

    // fix of local problem with SSRF
    isLocalEnv && replaceDataWithLocalhost(meta);

    try {
        const response: ValidateResponse & PostInitResponse = yield api.payment.postInit(meta);

        const isPendingStatus = response.is_pending;

        payload.data.amountWithoutCommission = calculateLTV(currentProduct?.ltv, currentProduct?.period);
        payload.data.introductorySubscriptionPrice = currentProduct?.start_price;
        payload.data.productId = productId;
        payload.data.currentProduct = currentProduct;

        if (!response.result) throw response;

        if (isSendPurchase && !isPendingStatus) {
            yield call(sendAnalyticPurchase, payload);
        }

        if (isPendingStatus) {
            yield call(sendAnalyticPurchasePending, payload);
        }

        yield put({ type: SET_IS_PAID, payload: true });
        yield put({ type: actionTypes.SET_SUBSCRIPTION_ID, payload: order?.subscription_id });
        yield put({ type: actionTypes.VALIDATE_PAYMENT_DATA, payload: { ...response, result: true } });
        // need set this value for upgrade plan flow
        yield put({ type: actionTypes.SET_MAIN_PRODUCT, payload: currentProduct });
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    } catch (error) {
        console.error('error', error);
        alert('Something went wrong with payment validate: ' + error);

        sentry.logError(error, SENTRY_PAYMENT, ERROR_LEVELS.CRITICAL, {
            ...currentProduct,
            ...meta
        });

        yield put({ type: actionTypes.VALIDATE_PAYMENT_FAIL, payload: error });
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function* sendAnalyticPurchase(payload: ValidatePayload) {
    const {
        amountWithoutCommission,
        introductorySubscriptionPrice,
        order: { amount, currency, subscription_id },
        brand,
        productId,
        currentProduct,
    } = payload.data;

    const purchaseWithoutValue: boolean = yield select(getPurchaseWithoutValue);
    const userId: number = yield select(getUserId);
    const testaniaName: string = yield select(getTestaniaName);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const pageUrl = deleteSlash(payload.screenId);
    const content_id = getContentId({
        paymentType: currentProduct?.payment_type,
        currentProductCode: currentProduct?.product_code,
        trialPeriod: currentProduct?.trial,
        regularPeriod: currentProduct?.period,
        trialPrice: fromPennyToInt(amount),
        regularPrice: fromPennyToInt(introductorySubscriptionPrice),
    });

    const urlParams = generateQueryParams();

    const options = {
        currency: currency,
        value: amountWithoutCommission,
        content_id,
        subscription_price: fromPennyToInt(introductorySubscriptionPrice),
        price: fromPennyToInt(amount),
        payment: paymentMethodName,
        card_type: brand,
        user_id: userId,
        order_id: payload?.data?.order?.order_id,
        subscription_id: subscription_id,
        screen_id: pageUrl,
        tariff: productId,
        ab_test_name: testaniaName,
        urlParams,
        order_type: currentProduct?.payment_type,
    };

    const sendAnalyticsList = payload.analytics || DEFAULT_ACTIVE_ANALYTICS;

    const sendAnalyticPurchaseWithSelectedSystem = () =>
        Main.purchase(cleanObject(options), { selectedSystems: ['amazon'] });
    const sendDefaultAnalyticPurchase = () => {
        Main.purchase(cleanObject(options), { selectedSystems: sendAnalyticsList });
        Yahoo.push({ et: 'custom', ea: 'purchase', gv: String(options.value) });
    };

    purchaseWithoutValue ? sendAnalyticPurchaseWithSelectedSystem() : sendDefaultAnalyticPurchase();
}

function* sendAnalyticPurchasePending(payload: ValidatePayload) {
    const {
        amountWithoutCommission,
        introductorySubscriptionPrice,
        order: { amount, currency, subscription_id },
        brand,
        productId,
        currentProduct,
    } = payload.data;

    const userId: number = yield select(getUserId);
    const testaniaName: string = yield select(getTestaniaName);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);

    const paymentMethodName: string | null = PAYMENT_TYPES_NAME[paymentMethod] || null;

    const pageUrl = deleteSlash(payload.screenId);
    const content_id = getContentId({
        paymentType: currentProduct?.payment_type,
        currentProductCode: currentProduct?.product_code,
        trialPeriod: currentProduct?.trial,
        regularPeriod: currentProduct?.period,
        trialPrice: fromPennyToInt(amount),
        regularPrice: fromPennyToInt(introductorySubscriptionPrice),
    });
    const urlParams = generateQueryParams();

    const options = {
        currency: currency,
        value: amountWithoutCommission,
        content_id,
        subscription_price: fromPennyToInt(introductorySubscriptionPrice),
        price: fromPennyToInt(amount),
        payment: paymentMethodName,
        card_type: brand,
        user_id: userId,
        order_id: payload?.data?.order?.order_id,
        subscription_id: subscription_id,
        screen_id: pageUrl,
        tariff: productId,
        ab_test_name: testaniaName,
        urlParams,
        order_type: currentProduct?.payment_type,
    };

    Main.customData('purch_pending', cleanObject(options));
    Yahoo.push({ et: 'custom', ea: 'purch_pending', gv: String(options.value) });
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];
